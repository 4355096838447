/**
 * Import Transactions Component
 * 
 * A modal component that handles bulk transaction imports from:
 * 1. CSV files
 * 2. Google Sheets URLs
 * 
 * Features:
 * - Multiple import sources
 * - Data validation
 * - Error handling
 * - Progress tracking
 * - Format verification
 * 
 * Dependencies:
 * - papaparse: CSV parsing
 * - @/components/ui/*: UI components
 * - @/lib/supabase: Database operations
 * - @/hooks/use-toast: Notifications
 */

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import Papa from 'papaparse';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { supabase } from '@/lib/supabase';
import { useToast } from '@/hooks/use-toast';

const urlSchema = z.object({
  url: z.string().url('Please enter a valid URL'),
});

interface ImportTransactionsProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function ImportTransactions({ open, onOpenChange }: ImportTransactionsProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();
  const form = useForm({
    resolver: zodResolver(urlSchema),
  });

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setIsLoading(true);
    try {
      const text = await file.text();
      Papa.parse(text, {
        header: true,
        complete: async (results) => {
          try {
            const { data: { user } } = await supabase.auth.getUser();
            if (!user) throw new Error('No user found');

            const transactions = results.data.map((row: any) => ({
              amount: parseFloat(row.amount),
              description: row.description,
              category: row.category,
              type: row.type,
              user_id: user.id,
              created_at: new Date(`${row.date}T${row.time}`).toISOString(),
            }));

            const { error } = await supabase
              .from('transactions')
              .insert(transactions);

            if (error) throw error;

            toast({
              title: 'Success',
              description: `Imported ${transactions.length} transactions`,
            });

            onOpenChange(false);
          } catch (error: any) {
            toast({
              variant: 'destructive',
              title: 'Error',
              description: error.message,
            });
          }
        },
        error: (error) => {
          toast({
            variant: 'destructive',
            title: 'Error',
            description: error.message,
          });
        },
      });
    } catch (error: any) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmitUrl = async (data: z.infer<typeof urlSchema>) => {
    setIsLoading(true);
    try {
      const response = await fetch(data.url);
      const text = await response.text();
      
      Papa.parse(text, {
        header: true,
        complete: async (results) => {
          try {
            const { data: { user } } = await supabase.auth.getUser();
            if (!user) throw new Error('No user found');

            const transactions = results.data.map((row: any) => ({
              amount: parseFloat(row.amount),
              description: row.description,
              category: row.category,
              type: row.type,
              user_id: user.id,
              created_at: new Date(`${row.date}T${row.time}`).toISOString(),
            }));

            const { error } = await supabase
              .from('transactions')
              .insert(transactions);

            if (error) throw error;

            toast({
              title: 'Success',
              description: `Imported ${transactions.length} transactions`,
            });

            form.reset();
            onOpenChange(false);
          } catch (error: any) {
            toast({
              variant: 'destructive',
              title: 'Error',
              description: error.message,
            });
          }
        },
        error: (error) => {
          toast({
            variant: 'destructive',
            title: 'Error',
            description: error.message,
          });
        },
      });
    } catch (error: any) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="dialog-content">
        <DialogHeader>
          <DialogTitle className="text-gray-900">Import Transactions</DialogTitle>
        </DialogHeader>
        <Tabs defaultValue="file" className="w-full">
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="file">CSV File</TabsTrigger>
            <TabsTrigger value="url">Google Sheets URL</TabsTrigger>
          </TabsList>
          <TabsContent value="file" className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="file" className="form-label">Upload CSV File</Label>
              <Input
                id="file"
                type="file"
                accept=".csv"
                onChange={handleFileUpload}
                disabled={isLoading}
                className="form-input"
              />
            </div>
          </TabsContent>
          <TabsContent value="url" className="space-y-4">
            <form onSubmit={form.handleSubmit(onSubmitUrl)} className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="url" className="form-label">Google Sheets URL</Label>
                <Input
                  id="url"
                  placeholder="Enter public CSV URL"
                  className="form-input"
                  {...form.register('url')}
                />
                {form.formState.errors.url && (
                  <p className="text-sm text-destructive">{form.formState.errors.url.message}</p>
                )}
              </div>
              <Button type="submit" className="w-full" disabled={isLoading}>
                {isLoading ? "Importing..." : "Import"}
              </Button>
            </form>
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
}