/**
 * Overview Chart Component
 * 
 * A data visualization component that displays:
 * 1. Income and expense trends over time
 * 2. Bar chart comparison of financial data
 * 3. Formatted currency values on axes
 * 
 * Features:
 * - Responsive design
 * - Custom styling for income/expense bars
 * - Automatic data aggregation by date
 * 
 * Dependencies:
 * - recharts: For chart rendering
 * - date-fns: For date formatting
 * - @/lib/utils: For currency formatting
 */

import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from '@/components/ui/chart';
import { format, parseISO } from 'date-fns';
import { formatCurrency } from '@/lib/utils';
import type { Transaction } from '@/types/transaction';

interface ChartData {
  name: string;
  income: number;
  expenses: number;
}

interface OverviewChartProps {
  data: Transaction[];
}

export function OverviewChart({ data }: OverviewChartProps) {
  const chartData: ChartData[] = data.reduce((acc: ChartData[], transaction) => {
    const date = format(parseISO(transaction.created_at), 'MMM dd');
    const existingDay = acc.find(d => d.name === date);

    if (existingDay) {
      if (transaction.type === 'income') {
        existingDay.income += transaction.amount;
      } else {
        existingDay.expenses += transaction.amount;
      }
      return acc;
    }

    return [...acc, {
      name: date,
      income: transaction.type === 'income' ? transaction.amount : 0,
      expenses: transaction.type === 'expense' ? transaction.amount : 0,
    }];
  }, []);

  return (
    <ResponsiveContainer width="100%" height={350}>
      <BarChart data={chartData}>
        <XAxis
          dataKey="name"
          stroke="currentColor"
          fontSize={12}
          tickLine={false}
          axisLine={false}
        />
        <YAxis
          stroke="currentColor"
          fontSize={12}
          tickLine={false}
          axisLine={false}
          tickFormatter={value => formatCurrency(value)}
        />
        <Bar
          dataKey="income"
          fill="hsl(var(--chart-1))"
          radius={[4, 4, 0, 0]}
        />
        <Bar
          dataKey="expenses"
          fill="hsl(var(--chart-2))"
          radius={[4, 4, 0, 0]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}