import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { format } from 'date-fns';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Combobox } from '@/components/ui/combobox';
import { supabase } from '@/lib/supabase';
import { useToast } from '@/hooks/use-toast';
import type { Transaction } from '@/types/transaction';

const transactionSchema = z.object({
  amount: z.string().min(1, 'Amount is required'),
  description: z.string().min(1, 'Description is required'),
  category: z.string().min(1, 'Category is required'),
  type: z.enum(['income', 'expense', 'subscription']),
  date: z.string().min(1, 'Date is required'),
  time: z.string().min(1, 'Time is required'),
});

const categoryMap = {
  income: ['Salary', 'Investment', 'Management', 'Freelance', 'Tax Cut', 'Other'],
  expense: ['Food', 'Transport', 'Entertainment', 'Shopping', 'Bills', 'Other'],
  subscription: ['Streaming', 'Software', 'Membership', 'Other'],
};

interface EditTransactionProps {
  transaction: Transaction | null;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSuccess: () => void;
}

export function EditTransaction({ transaction, open, onOpenChange, onSuccess }: EditTransactionProps) {
  const { toast } = useToast();
  const form = useForm({
    resolver: zodResolver(transactionSchema),
  });

  useEffect(() => {
    if (transaction) {
      const date = new Date(transaction.created_at);
      form.reset({
        amount: transaction.amount.toString(),
        description: transaction.description || '',
        category: transaction.category || '',
        type: transaction.type,
        date: format(date, 'yyyy-MM-dd'),
        time: format(date, 'HH:mm'),
      });
    }
  }, [transaction, form]);

  const currentType = form.watch('type') || 'expense';
  const categories = categoryMap[currentType];

  async function onSubmit(data: z.infer<typeof transactionSchema>) {
    if (!transaction?.id) return;

    try {
      const timestamp = new Date(`${data.date}T${data.time}`);

      const { error } = await supabase
        .from('transactions')
        .update({
          amount: parseFloat(data.amount),
          description: data.description,
          category: data.category,
          type: data.type,
          created_at: timestamp.toISOString(),
        })
        .eq('id', transaction.id);

      if (error) throw error;

      toast({
        title: 'Success',
        description: 'Transaction updated successfully',
      });
      
      onSuccess();
      onOpenChange(false);
    } catch (error: any) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: error.message,
      });
    }
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Transaction</DialogTitle>
        </DialogHeader>
        <Tabs
          defaultValue={transaction?.type || 'expense'}
          className="w-full"
          onValueChange={(value) => {
            form.setValue('type', value as 'income' | 'expense' | 'subscription');
            form.setValue('category', '');
          }}
        >
          <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger value="income">Income</TabsTrigger>
            <TabsTrigger value="expense">Expense</TabsTrigger>
            <TabsTrigger value="subscription">Subscription</TabsTrigger>
          </TabsList>
        </Tabs>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 mt-4">
          <div className="space-y-2">
            <Label htmlFor="amount">Amount</Label>
            <Input
              id="amount"
              type="number"
              step="0.01"
              placeholder="0.00"
              {...form.register('amount')}
            />
            {form.formState.errors.amount && (
              <p className="text-sm text-destructive">{form.formState.errors.amount.message}</p>
            )}
          </div>

          <div className="space-y-2">
            <Label htmlFor="description">Description</Label>
            <Input
              id="description"
              placeholder="Enter description"
              {...form.register('description')}
            />
            {form.formState.errors.description && (
              <p className="text-sm text-destructive">{form.formState.errors.description.message}</p>
            )}
          </div>

          <div className="space-y-2">
            <Label>Category</Label>
            <Controller
              control={form.control}
              name="category"
              render={({ field }) => (
                <Combobox
                  options={categories}
                  value={field.value}
                  onSelect={field.onChange}
                  placeholder="Select category..."
                />
              )}
            />
            {form.formState.errors.category && (
              <p className="text-sm text-destructive">{form.formState.errors.category.message}</p>
            )}
          </div>

          <div className="grid gap-4 sm:grid-cols-2">
            <div className="space-y-2">
              <Label htmlFor="date">Date</Label>
              <Input
                id="date"
                type="date"
                {...form.register('date')}
              />
              {form.formState.errors.date && (
                <p className="text-sm text-destructive">{form.formState.errors.date.message}</p>
              )}
            </div>

            <div className="space-y-2">
              <Label htmlFor="time">Time</Label>
              <Input
                id="time"
                type="time"
                {...form.register('time')}
              />
              {form.formState.errors.time && (
                <p className="text-sm text-destructive">{form.formState.errors.time.message}</p>
              )}
            </div>
          </div>

          <Button type="submit" className="w-full">
            Update Transaction
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
}