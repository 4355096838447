/**
 * Header Component
 * 
 * Main navigation header with:
 * 1. Row 1: Logo and Sign out button
 * 2. Row 2: Navigation tabs
 * 
 * Features:
 * - Two-row layout with distinct sections
 * - Dark theme with bezel effect
 * - Responsive design
 * - Active tab indication with 12px spacing and rounded corners
 * - Hover effects
 * 
 * Dependencies:
 * - @/components/ui/button: UI components
 * - lucide-react: Icons
 * - @/lib/utils: Utility functions
 */

import { LogOut } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

interface HeaderProps {
  activeTab: 'dashboard' | 'release-notes';
  onTabChange: (tab: 'dashboard' | 'release-notes') => void;
  onSignOut: () => void;
}

export function Header({ activeTab, onTabChange, onSignOut }: HeaderProps) {
  return (
    <header className="sticky top-0 z-50 w-full border-b border-white/10 bg-[#1a1a1a]">
      {/* Row 1: Logo and Sign out */}
      <div className="container flex h-16 items-center justify-between border-b border-white/10">
        <div className="flex items-center">
          <img 
            src="/yamparala-logo.svg" 
            alt="Yamparala Design" 
            className="h-6 w-auto"
          />
        </div>
        <Button
          variant="ghost"
          size="sm"
          onClick={onSignOut}
          className="h-9 w-fit px-3 text-white hover:bg-white/10"
        >
          <LogOut className="mr-2 h-4 w-4" />
          Sign out
        </Button>
      </div>

      {/* Row 2: Navigation Tabs */}
      <div className="container h-12">
        <nav className="flex h-full space-x-6">
          <button
            onClick={() => onTabChange('dashboard')}
            className={cn(
              "nav-link relative flex items-center",
              activeTab === 'dashboard' ? "nav-link-active" : "nav-link-inactive",
              activeTab === 'dashboard' && "after:absolute after:bottom-[-12px] after:left-0 after:right-0 after:h-0.5 after:bg-white after:rounded-full"
            )}
          >
            Dashboard
          </button>
          <button
            onClick={() => onTabChange('release-notes')}
            className={cn(
              "nav-link relative flex items-center",
              activeTab === 'release-notes' ? "nav-link-active" : "nav-link-inactive",
              activeTab === 'release-notes' && "after:absolute after:bottom-[-12px] after:left-0 after:right-0 after:h-0.5 after:bg-white after:rounded-full"
            )}
          >
            Release Notes
          </button>
        </nav>
      </div>
    </header>
  );
}