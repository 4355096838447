/**
 * Add Transaction Component
 * 
 * A modal component for adding new financial transactions with features:
 * 1. Multi-type transaction support (income/expense/subscription)
 * 2. Dynamic category management with custom categories
 * 3. Date and time selection
 * 4. Subscription period handling
 * 
 * Features:
 * - Form validation using Zod
 * - Custom category creation
 * - Real-time validation
 * - Subscription management
 */

import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Combobox } from '@/components/ui/combobox';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { supabase } from '@/lib/supabase';
import { useToast } from '@/hooks/use-toast';

const transactionSchema = z.object({
  amount: z.string().min(1, 'Amount is required'),
  description: z.string().min(1, 'Description is required'),
  category: z.string().min(1, 'Category is required'),
  type: z.enum(['income', 'expense', 'subscription']),
  date: z.string().min(1, 'Date is required'),
  time: z.string().min(1, 'Time is required'),
  subscription_period: z.object({
    type: z.enum(['monthly', 'yearly', 'days']),
    value: z.number().min(1),
  }).optional(),
});

const categoryMap = {
  income: ['Salary', 'Investment', 'Management', 'Freelance', 'Tax Cut', 'Other'],
  expense: ['Food', 'Transport', 'Entertainment', 'Shopping', 'Bills', 'Other'],
  subscription: ['Streaming', 'Software', 'Membership', 'Other'],
};

interface AddTransactionProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function AddTransaction({ open, onOpenChange }: AddTransactionProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [customCategories, setCustomCategories] = useState<Record<string, string[]>>({
    income: [],
    expense: [],
    subscription: [],
  });
  const { toast } = useToast();
  
  const form = useForm({
    resolver: zodResolver(transactionSchema),
    defaultValues: {
      amount: '',
      description: '',
      category: '',
      type: 'expense' as const,
      date: new Date().toISOString().split('T')[0],
      time: new Date().toTimeString().slice(0, 5),
      subscription_period: undefined,
    },
  });

  const currentType = form.watch('type') || 'expense';
  const categories = [...categoryMap[currentType], ...customCategories[currentType]];

  const handleAddCustomCategory = (category: string) => {
    if (!categories.includes(category)) {
      setCustomCategories(prev => ({
        ...prev,
        [currentType]: [...prev[currentType], category],
      }));
      form.setValue('category', category);
    }
  };

  async function onSubmit(data: z.infer<typeof transactionSchema>) {
    setIsLoading(true);
    try {
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) throw new Error('No user found');

      const timestamp = new Date(`${data.date}T${data.time}`);

      const transactionData: any = {
        amount: parseFloat(data.amount),
        description: data.description,
        category: data.category,
        type: data.type,
        user_id: user.id,
        created_at: timestamp.toISOString(),
      };

      if (data.type === 'subscription' && data.subscription_period) {
        transactionData.subscription_period = data.subscription_period;
      }

      const { error } = await supabase
        .from('transactions')
        .insert([transactionData]);

      if (error) throw error;

      toast({
        title: 'Success',
        description: 'Transaction added successfully',
      });
      
      form.reset();
      onOpenChange(false);
    } catch (error: any) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: error.message,
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="dialog-content">
        <DialogHeader>
          <DialogTitle className="text-gray-900">Add Transaction</DialogTitle>
        </DialogHeader>
        <Tabs
          defaultValue="expense"
          className="w-full"
          onValueChange={(value) => {
            form.setValue('type', value as 'income' | 'expense' | 'subscription');
            form.setValue('category', '');
            if (value !== 'subscription') {
              form.setValue('subscription_period', undefined);
            }
          }}
        >
          <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger value="income">Income</TabsTrigger>
            <TabsTrigger value="expense">Expense</TabsTrigger>
            <TabsTrigger value="subscription">Subscription</TabsTrigger>
          </TabsList>
        </Tabs>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 mt-4">
          <div className="space-y-2">
            <Label htmlFor="amount" className="form-label">Amount</Label>
            <Input
              id="amount"
              type="number"
              step="0.01"
              placeholder="0.00"
              className="form-input"
              {...form.register('amount')}
            />
            {form.formState.errors.amount && (
              <p className="text-sm text-destructive">{form.formState.errors.amount.message}</p>
            )}
          </div>

          <div className="space-y-2">
            <Label htmlFor="description" className="form-label">Description</Label>
            <Input
              id="description"
              placeholder="Enter description"
              className="form-input"
              {...form.register('description')}
            />
            {form.formState.errors.description && (
              <p className="text-sm text-destructive">{form.formState.errors.description.message}</p>
            )}
          </div>

          <div className="space-y-2">
            <Label className="form-label">Category</Label>
            <Controller
              control={form.control}
              name="category"
              render={({ field }) => (
                <Combobox
                  options={categories}
                  value={field.value}
                  onSelect={field.onChange}
                  onCreateOption={handleAddCustomCategory}
                  placeholder="Select or type to add category..."
                  allowCustomValue
                  className="form-input"
                />
              )}
            />
            {form.formState.errors.category && (
              <p className="text-sm text-destructive">{form.formState.errors.category.message}</p>
            )}
          </div>

          {currentType === 'subscription' && (
            <div className="space-y-4">
              <div className="space-y-2">
                <Label className="form-label">Subscription Period</Label>
                <div className="flex gap-4">
                  <div className="flex-1">
                    <Controller
                      control={form.control}
                      name="subscription_period.type"
                      render={({ field }) => (
                        <Select
                          value={field.value}
                          onValueChange={field.onChange}
                        >
                          <SelectTrigger className="form-input">
                            <SelectValue placeholder="Select period" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="monthly">Monthly</SelectItem>
                            <SelectItem value="yearly">Yearly</SelectItem>
                            <SelectItem value="days">Days</SelectItem>
                          </SelectContent>
                        </Select>
                      )}
                    />
                  </div>
                  <div className="flex-1">
                    <Controller
                      control={form.control}
                      name="subscription_period.value"
                      render={({ field }) => (
                        <Input
                          type="number"
                          min="1"
                          placeholder={form.watch('subscription_period.type') === 'days' ? 'Number of days' : 'Repeat every'}
                          className="form-input"
                          onChange={(e) => field.onChange(parseInt(e.target.value))}
                          value={field.value || ''}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="grid gap-4 sm:grid-cols-2">
            <div className="space-y-2">
              <Label htmlFor="date" className="form-label">Date</Label>
              <Input
                id="date"
                type="date"
                className="form-input"
                {...form.register('date')}
              />
              {form.formState.errors.date && (
                <p className="text-sm text-destructive">{form.formState.errors.date.message}</p>
              )}
            </div>

            <div className="space-y-2">
              <Label htmlFor="time" className="form-label">Time</Label>
              <Input
                id="time"
                type="time"
                className="form-input"
                {...form.register('time')}
              />
              {form.formState.errors.time && (
                <p className="text-sm text-destructive">{form.formState.errors.time.message}</p>
              )}
            </div>
          </div>

          <Button type="submit" className="w-full" disabled={isLoading}>
            {isLoading ? "Adding..." : "Add Transaction"}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
}