/**
 * Overview Component
 * 
 * A comprehensive dashboard component that displays:
 * 1. Financial overview with tabs for Master Sheet and Analytics
 * 2. Real-time transaction data visualization
 * 3. Period-based filtering (7 days/30 days)
 * 
 * Features:
 * - Real-time updates using Supabase subscriptions
 * - Automatic data refresh
 * - Period-based data filtering
 * 
 * Dependencies:
 * - @/components/ui/card: Card components for layout
 * - @/components/ui/tabs: Tab navigation
 * - @/components/dashboard/overview-chart: Chart visualization
 * - @/lib/supabase: Database connection
 * - @/lib/utils: Utility functions
 */

import { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Button } from '@/components/ui/button';
import { Clock } from 'lucide-react';
import { OverviewChart } from '@/components/dashboard/overview-chart';
import { supabase } from '@/lib/supabase';
import { formatCurrency } from '@/lib/utils';
import type { Transaction } from '@/types/transaction';

export function Overview() {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [period, setPeriod] = useState<'7days' | '30days'>('7days');
  const [lastSyncTime, setLastSyncTime] = useState(new Date());

  const fetchTransactions = async () => {
    const { data } = await supabase
      .from('transactions')
      .select('*')
      .order('created_at', { ascending: false });

    if (data) {
      setTransactions(data);
      setLastSyncTime(new Date());
    }
  };

  useEffect(() => {
    fetchTransactions();

    const channel = supabase
      .channel('public:transactions')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'transactions'
        },
        () => {
          fetchTransactions();
        }
      )
      .subscribe();

    return () => {
      channel.unsubscribe();
    };
  }, [period]);

  const income = transactions
    .filter(t => t.type === 'income')
    .reduce((sum, t) => sum + t.amount, 0);

  const expenses = transactions
    .filter(t => t.type === 'expense')
    .reduce((sum, t) => sum + t.amount, 0);

  const balance = income - expenses;

  return (
    <Tabs defaultValue="master-sheet" className="space-y-4">
      <div className="flex items-center justify-between">
        <TabsList>
          <TabsTrigger value="master-sheet">Master Sheet</TabsTrigger>
          <TabsTrigger value="analytics">Analytics</TabsTrigger>
        </TabsList>
        <div className="flex items-center text-sm text-muted-foreground">
          <Clock className="mr-2 h-4 w-4" />
          Last synced: {lastSyncTime.toLocaleTimeString()}
        </div>
      </div>
      <TabsContent value="master-sheet" className="space-y-4">
        <div className="grid gap-4 md:grid-cols-3">
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">Balance</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{formatCurrency(balance)}</div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">Income</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold text-green-500">{formatCurrency(income)}</div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">Expenses</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold text-red-500">{formatCurrency(expenses)}</div>
            </CardContent>
          </Card>
        </div>
      </TabsContent>
      <TabsContent value="analytics" className="space-y-4">
        <Card>
          <CardHeader>
            <div className="flex items-center justify-between">
              <CardTitle>Overview</CardTitle>
              <div className="flex space-x-2">
                <Button
                  variant={period === '7days' ? 'default' : 'outline'}
                  size="sm"
                  onClick={() => setPeriod('7days')}
                >
                  7 days
                </Button>
                <Button
                  variant={period === '30days' ? 'default' : 'outline'}
                  size="sm"
                  onClick={() => setPeriod('30days')}
                >
                  30 days
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardContent className="pl-2">
            <OverviewChart data={transactions} />
          </CardContent>
        </Card>
      </TabsContent>
    </Tabs>
  );
}