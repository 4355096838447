/**
 * Main Application Component
 * 
 * This is the root component of the application that handles:
 * 1. Authentication state management using Supabase
 * 2. Theme provider integration
 * 3. Main layout switching between Auth and Dashboard
 * 
 * Dependencies:
 * - @/components/theme/theme-provider: Handles application theming
 * - @/components/layouts/*: Contains layout components
 * - @/lib/supabase: Supabase client configuration
 * 
 * State:
 * - session: Tracks user authentication state
 * - loading: Manages initial loading state
 * 
 * Flow:
 * 1. Checks for existing session on mount
 * 2. Sets up auth state change listener
 * 3. Renders appropriate layout based on auth state
 */

import { useEffect, useState } from 'react';
import { ThemeProvider } from '@/components/theme/theme-provider';
import { Toaster } from '@/components/ui/toaster';
import { AuthLayout } from '@/components/layouts/auth-layout';
import { DashboardLayout } from '@/components/layouts/dashboard-layout';
import { Loading } from '@/components/ui/loading';
import { supabase } from '@/lib/supabase';
import type { Session } from '@supabase/supabase-js';

function App() {
  const [session, setSession] = useState<Session | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Initialize session check
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setLoading(false);
    });

    // Set up auth state change listener
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    // Cleanup subscription on unmount
    return () => subscription.unsubscribe();
  }, []);

  if (loading) return <Loading />;

  return (
    <ThemeProvider>
      <main className="min-h-screen bg-background font-sans antialiased">
        {!session ? <AuthLayout /> : <DashboardLayout />}
      </main>
      <Toaster />
    </ThemeProvider>
  );
}

export default App;