/**
 * Release Notes Component
 * 
 * Displays project updates and developer information:
 * 1. Chronological list of updates with timestamps
 * 2. Developer profile section with image
 * 3. Social links and portfolio information
 * 
 * Features:
 * - Timeline-based update history
 * - Categorized updates with details
 * - Profile card with contact info
 * - External links to portfolio and social
 * 
 * Dependencies:
 * - lucide-react: For social icons
 * - @/components/ui/card: For profile section
 */

import { Globe, Twitter } from 'lucide-react';
import { Card } from '@/components/ui/card';

export function ReleaseNotes() {
  return (
    <div className="container">
      <div className="content-area space-y-8">
        <div className="space-y-6">
          <h2 className="text-2xl font-bold text-gray-900">Release Notes</h2>
          
          {/* Release entries */}
          <div className="space-y-8">
            <div className="border-l-2 border-gray-200 pl-4">
              <div className="text-sm text-gray-500">March 26, 2024 • 2:30 PM</div>
              <h3 className="mt-2 text-lg font-semibold text-gray-900">Release Notes & UI Improvements</h3>
              <div className="mt-2 space-y-4">
                <div>
                  <h4 className="font-medium text-gray-900">Updates:</h4>
                  <ul className="mt-1 list-disc list-inside text-gray-600">
                    <li>Added Release Notes section with timeline view</li>
                    <li>Implemented dark theme for application shell</li>
                    <li>Enhanced dialog contrast and text readability</li>
                    <li>Added developer profile section</li>
                    <li>Improved navigation with new tab design</li>
                    <li>Fixed alert dialog text colors</li>
                  </ul>
                </div>
                <div>
                  <h4 className="font-medium text-gray-900">Remarks:</h4>
                  <p className="mt-1 text-gray-600">
                    UI/UX improvements for better user experience and accessibility
                  </p>
                </div>
              </div>
            </div>

            <div className="border-l-2 border-gray-200 pl-4">
              <div className="text-sm text-gray-500">March 25, 2024 • 11:30 AM</div>
              <h3 className="mt-2 text-lg font-semibold text-gray-900">UI Enhancement Update</h3>
              <div className="mt-2 space-y-4">
                <div>
                  <h4 className="font-medium text-gray-900">Updates:</h4>
                  <ul className="mt-1 list-disc list-inside text-gray-600">
                    <li>Enhanced header design with two-row layout</li>
                    <li>Improved navigation with tab indicators</li>
                    <li>Fixed dialog contrast issues</li>
                    <li>Added dark theme support</li>
                  </ul>
                </div>
                <div>
                  <h4 className="font-medium text-gray-900">Remarks:</h4>
                  <p className="mt-1 text-gray-600">
                    Design improvements suggested by the UX team for better visual hierarchy
                  </p>
                </div>
              </div>
            </div>

            <div className="border-l-2 border-gray-200 pl-4">
              <div className="text-sm text-gray-500">March 24, 2024 • 3:45 PM</div>
              <h3 className="mt-2 text-lg font-semibold text-gray-900">Import Feature Release</h3>
              <div className="mt-2 space-y-4">
                <div>
                  <h4 className="font-medium text-gray-900">Updates:</h4>
                  <ul className="mt-1 list-disc list-inside text-gray-600">
                    <li>Added CSV import functionality</li>
                    <li>Implemented Google Sheets integration</li>
                    <li>Added data validation</li>
                    <li>Enhanced error handling</li>
                  </ul>
                </div>
                <div>
                  <h4 className="font-medium text-gray-900">Remarks:</h4>
                  <p className="mt-1 text-gray-600">
                    Feature requested by users for bulk transaction imports
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Profile Card */}
        <Card className="p-6 mt-8">
          <div className="flex items-start space-x-6">
            <div className="flex-shrink-0">
              <img 
                src="https://chkudqrrbhzegmewospk.supabase.co/storage/v1/object/public/profile/yamparala-profile.jpg"
                alt="Yamparala Rahul"
                className="w-24 h-24 rounded-full object-cover"
              />
            </div>
            <div>
              <h3 className="text-xl font-semibold text-gray-900">Yamparala Rahul</h3>
              <p className="mt-2 text-gray-600">
                Product designer who can Ship. Passionate about creating intuitive and beautiful user experiences.
              </p>
              <div className="mt-4 flex space-x-4">
                <a 
                  href="https://www.yamparala.design" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="inline-flex items-center text-gray-600 hover:text-gray-900"
                >
                  <Globe className="h-4 w-4 mr-1" />
                  Portfolio
                </a>
                <a 
                  href="https://twitter.com/yamparalarahul1" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="inline-flex items-center text-gray-600 hover:text-gray-900"
                >
                  <Twitter className="h-4 w-4 mr-1" />
                  @yamparalarahul1
                </a>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}