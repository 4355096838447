/**
 * Authentication Layout Component
 * 
 * Primary authentication interface that handles:
 * 1. User sign-in and sign-up flows
 * 2. Form validation and error handling
 * 3. Authentication state management
 * 
 * Features:
 * - Toggle between sign-in and sign-up modes
 * - Form validation using Zod
 * - Responsive design with mobile optimization
 * - Error handling with toast notifications
 * 
 * Dependencies:
 * - @hookform/resolvers/zod: Form validation
 * - @/components/ui/*: UI components
 * - @/hooks/use-toast: Toast notifications
 * - @/lib/supabase: Authentication service
 * 
 * State Management:
 * - isLoading: Tracks form submission state
 * - isSignUp: Toggles between sign-in/sign-up modes
 * 
 * Related Files:
 * - src/lib/supabase.ts: Authentication service
 * - src/hooks/use-toast.ts: Notification system
 */

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Wallet } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { useToast } from '@/hooks/use-toast';
import { supabase } from '@/lib/supabase';

// Form validation schema
const authSchema = z.object({
  email: z.string().email('Invalid email address'),
  password: z.string().min(6, 'Password must be at least 6 characters'),
});

type AuthFormValues = z.infer<typeof authSchema>;

export function AuthLayout() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const { toast } = useToast();

  const form = useForm<AuthFormValues>({
    resolver: zodResolver(authSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  async function onSubmit(data: AuthFormValues) {
    setIsLoading(true);
    try {
      if (isSignUp) {
        const { error } = await supabase.auth.signUp(data);
        if (error) throw error;
        toast({
          title: 'Check your email',
          description: 'We sent you a verification link',
        });
      } else {
        const { error } = await supabase.auth.signInWithPassword(data);
        if (error) throw error;
      }
    } catch (error: any) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: error.message,
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="container relative min-h-screen flex-col items-center justify-center grid lg:max-w-none lg:grid-cols-2 lg:px-0">
      <div className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r">
        <div className="absolute inset-0 bg-zinc-900" />
        <div className="relative z-20 flex items-center text-lg font-medium">
          <Wallet className="mr-2 h-6 w-6" />
          Yamparala App
        </div>
        <div className="relative z-20 mt-auto">
          <blockquote className="space-y-2">
            <p className="text-lg">
              This app is designed & build by Yamparala Rahul. A Product designer who can Ship, More about him at{" "}
              <a 
                href="https://www.yamparala.design" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-blue-300 hover:text-blue-200 underline"
              >
                www.yamparala.design
              </a>{" "}
              and Connect on X @{" "}
              <a 
                href="https://twitter.com/yamparalarahul1" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-blue-300 hover:text-blue-200 underline"
              >
                yamparalarahul1
              </a>
            </p>
          </blockquote>
        </div>
      </div>
      <div className="p-[60px]">
        <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[400px]">
          <div className="flex lg:hidden items-center">
            <Wallet className="mr-2 h-6 w-6" />
            <span className="text-lg font-medium">Yamparala App</span>
          </div>
          <Card className="w-full">
            <CardHeader className="space-y-1">
              <CardTitle className="text-2xl">{isSignUp ? 'Create account' : 'Sign in'}</CardTitle>
              <CardDescription>
                Enter your email and password to {isSignUp ? 'create your account' : 'sign in to your account'}
              </CardDescription>
            </CardHeader>
            <CardContent>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="email">Email</Label>
                  <Input
                    id="email"
                    type="email"
                    placeholder="name@example.com"
                    {...form.register('email')}
                  />
                  {form.formState.errors.email && (
                    <p className="text-sm text-destructive">{form.formState.errors.email.message}</p>
                  )}
                </div>
                <div className="space-y-2">
                  <Label htmlFor="password">Password</Label>
                  <Input
                    id="password"
                    type="password"
                    {...form.register('password')}
                  />
                  {form.formState.errors.password && (
                    <p className="text-sm text-destructive">{form.formState.errors.password.message}</p>
                  )}
                </div>
                <Button className="w-full" type="submit" disabled={isLoading}>
                  {isLoading ? 'Loading...' : isSignUp ? 'Create account' : 'Sign in'}
                </Button>
              </form>
              <div className="mt-4 text-center text-sm">
                <Button
                  variant="link"
                  className="text-muted-foreground"
                  onClick={() => setIsSignUp(!isSignUp)}
                >
                  {isSignUp ? 'Already have an account? Sign in' : "Don't have an account? Sign up"}
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}