/**
 * Dashboard Layout Component
 * 
 * Main application dashboard that provides:
 * 1. Navigation and layout structure with dark theme
 * 2. Transaction management interface
 * 3. User session handling
 * 
 * Features:
 * - Dark themed header with Yamparala Design logo
 * - Responsive navigation tabs
 * - Transaction management modals
 * - Sign-out functionality
 * - Import/Export capabilities
 * 
 * Dependencies:
 * - @/components/dashboard/*: Dashboard components
 * - @/components/ui/*: UI components
 * - lucide-react: Icons
 * - @/lib/supabase: Authentication
 */

import { useState } from 'react';
import { Upload } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Overview } from '@/components/dashboard/overview';
import { RecentTransactions } from '@/components/dashboard/recent-transactions';
import { AddTransaction } from '@/components/dashboard/add-transaction';
import { ImportTransactions } from '@/components/dashboard/import-transactions';
import { ReleaseNotes } from './release-notes';
import { Header } from './header';
import { supabase } from '@/lib/supabase';

export function DashboardLayout() {
  const [showAddTransaction, setShowAddTransaction] = useState(false);
  const [showImportDialog, setShowImportDialog] = useState(false);
  const [activeTab, setActiveTab] = useState<'dashboard' | 'release-notes'>('dashboard');

  const handleSignOut = async () => {
    await supabase.auth.signOut();
  };

  return (
    <div className="min-h-screen bg-[#1a1a1a]">
      <Header 
        activeTab={activeTab}
        onTabChange={setActiveTab}
        onSignOut={handleSignOut}
      />
      <main className="flex-1">
        {activeTab === 'dashboard' ? (
          <div className="container">
            <div className="content-area">
              <div className="flex flex-col justify-between gap-4 sm:flex-row sm:items-center">
                <div>
                  <h2 className="text-3xl font-bold tracking-tight text-gray-900">Dashboard</h2>
                  <p className="text-muted-foreground">
                    Manage your finances and track your spending
                  </p>
                </div>
                <div className="flex flex-col gap-2 sm:flex-row">
                  <Button 
                    variant="outline"
                    onClick={() => setShowImportDialog(true)}
                    className="w-full sm:w-auto"
                  >
                    <Upload className="mr-2 h-4 w-4" />
                    Import
                  </Button>
                  <Button 
                    onClick={() => setShowAddTransaction(true)} 
                    className="w-full sm:w-auto"
                  >
                    Add Transaction
                  </Button>
                </div>
              </div>
              <div className="mt-8 space-y-8">
                <Overview />
                <RecentTransactions />
              </div>
            </div>
          </div>
        ) : (
          <ReleaseNotes />
        )}
      </main>
      <AddTransaction open={showAddTransaction} onOpenChange={setShowAddTransaction} />
      <ImportTransactions open={showImportDialog} onOpenChange={setShowImportDialog} />
    </div>
  );
}