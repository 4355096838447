import { createContext, useContext, ReactNode } from "react";

type ThemeProviderProps = {
  children: ReactNode;
};

const ThemeProviderContext = createContext<{ theme: 'light' }>({ theme: 'light' });

export function ThemeProvider({ children }: ThemeProviderProps) {
  return (
    <ThemeProviderContext.Provider value={{ theme: 'light' }}>
      {children}
    </ThemeProviderContext.Provider>
  );
}