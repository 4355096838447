/**
 * Utility Functions
 * 
 * This module contains common utility functions used throughout the application:
 * 1. cn: Combines class names with Tailwind CSS
 * 2. formatCurrency: Formats numbers as USD currency strings
 * 
 * Dependencies:
 * - clsx: For class name combination
 * - tailwind-merge: For Tailwind class merging
 * 
 * Usage:
 * - cn(): Used for combining Tailwind classes dynamically
 * - formatCurrency(): Used in transaction displays and forms
 */

import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatCurrency(amount: number): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);
}