/**
 * Supabase Client Configuration
 * 
 * This module initializes and exports the Supabase client for database operations.
 * It uses environment variables for configuration to maintain security.
 * 
 * Important:
 * - The client is configured for anonymous access
 * - Used throughout the application for all database operations
 * - Handles authentication and data storage
 * 
 * Security Note:
 * - Public key is safe to expose in client-side code
 * - Database rules should be configured in Supabase dashboard
 */

import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://chkudqrrbhzegmewospk.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImNoa3VkcXJyYmh6ZWdtZXdvc3BrIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzIyNzEwODIsImV4cCI6MjA0Nzg0NzA4Mn0.Guw7Z2SK3O1RSvjNTNDY2RILpO_4jQPzOlBor3gwC7g';

export const supabase = createClient(supabaseUrl, supabaseKey);